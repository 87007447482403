import {Accordion} from "react-bootstrap";
import {useState} from "react";
import { LG } from '../config/Index'

const lgtext = {
    lao:{
      1:'ໂປໂມຊັ້ນ',
      2:'ຮັບເຄດິດ',
      3:'ເມື່ອໂອນແລ້ວກົດປຸ່ມ "ຍືນຢັນຝາກ" ດ້ານລຸ່ມ',
      4:'ຍືນຢັນຝາກ',
    },
    thai:{
      1:'โปรโมชั่น',
      2:'รับเครดิต',
      3:'เมื่อโอนแล้วกรุณากด "ยืนยัน" ด้านล่าง',
      4:'ยืนยันฝาก',
    }
  }
function Promotion() {

    // User44
    /*
    const [banners] = useState([
        {name: 'banner1', image: './images/user44/pronew.jpg'},
        {name: 'banner2', image: './images/promotion/pro1.png'},
       // {name: 'banner3', image: './images/promotion/pro2.png'},
        {name: 'banner4', image: './images/user44/pro5.png'},
       // {name: 'banner5', image: './images/promotion/pro4.png'}
    ]) || [];
    */
    // User147
    /*
    const [banners] = useState([
        {name: 'banner1', image: './images/user147/pro4.png'},
        {name: 'banner2', image: './images/user147/pro3.png'},
        {name: 'banner3', image: './images/user147/pro2.png'},
        {name: 'banner4', image: './images/user147/pro1.png'},
       // {name: 'banner5', image: './images/promotion/pro4.png'}
    ]) || [];
    */
    // User95
    /*
    const [banners] = useState([
        {name: 'banner1', image: './images/user95/pron1.png'},
        {name: 'banner2', image: './images/user95/pron2.png'},
        {name: 'banner3', image: './images/user95/pron3.png'},
        {name: 'banner4', image: './images/user95/pron4.png'},
       // {name: 'banner5', image: './images/promotion/pro4.png'}
    ]) || [];
    */

    // Max1689
    
    const [banners] = useState([
        {name: 'banner1', image: './images/max1689/pro1.jpg'},
        {name: 'banner2', image: './images/max1689/pro3.jpg'},
        {name: 'cashback', image: './images/max1689/pro2.jpg'}
    ]) || [];
    
    return (
        <div>
            <div className="headsecion">
                <i className="far fa-gift"/> {lgtext[LG]['1']}
            </div>

            <div className="ctpersonal promotion">
                <div className="gridingame full">

                    {banners.map(banner => {
                        return <div className="ingridgame " key={banner.name}>
                            <div className="iningridgame pro">
                                <Accordion>
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header className={'p-0 m-0'}>
                                            <img className="accordion" src={banner.image}
                                                 alt={banner.name}/>
                                        </Accordion.Header>
                                        {
                                             banner.name == 'banner1' && <Accordion.Body>
                                             ‼️ ໂປຣສະຫມັກ 20% ເທີນ 8 ເທົ່າ ຮັບໂປຣສູງສຸດ 10,000 ບາດ(ຍອດເຕີມໂປຣ)
                                             <br />
                                             ‼️ ໂປຣແຖມ ສຳລັບສະມາຊິກໃຫມ່ ເຕີມ 500 ບາດຂື້ນໄປ<br />
                                             ‼️ຊື້ຫວຍຟຣີ 30,000 ກີບ <br />
                                              (ຕ້ອງຫລິ້ນເທີນກ່ອນ 8 ເທົ່າ ແລ້ວແຈ້ງແອດມິິນເພື່ອຊື້ເລກຟຣີ ຊື້ເລກຫຍັງກໍ່ໄດ້ ຊື້ຖືກຖອນໄດ້ທັງຫມົດ
                                             
                                        </Accordion.Body>
                                        }
                                        
                                        {
                                            banner.name == 'banner2' && <Accordion.Body>
                                            ໂປຣລາຍວັນ 5% ເຕີມສູງສຸດ 10,000 ບາດ <br /> ຮັບໂປຣສູງສຸດ 500 ບາດ
                                           ເທີນ 2 ເທົ່າ <br /> ເປັນໂປຣການເຕີມຍອດແລກຂອງມື້ (ໄດ້ທຸກມື້)
                                            </Accordion.Body>
                                        }

                                        {
                                            banner.name == 'banner3' && <Accordion.Body>
                                            ໂປຣປະຈຳອາທິດຄືນຍອດເສຍ 5% ຕໍ່ອາທິດ<br />
                                            (ຍອດເສຍເລີ່ມຕົ້ນ 200 ບາດ ສູງສຸດ 5,000 ບາດ)
                                            </Accordion.Body>
                                        }

                                        {
                                            banner.name == 'cashback' && <Accordion.Body>
                                            ໂປຣຍອດເສຍ 5% <br />
                                            (ຍອດເສຍເລີ່ມຕົ້ນ 200 ບາດ ສູງສຸດ 5,000 ບາດ)
                                            </Accordion.Body>
                                        }

                                        {
                                            banner.name == 'banner4' && <Accordion.Body>
                                           ໂປຊື້ເລກຟຮີ 25,000 ກີບໃນມື້ເລກລາວພັດທະນາ <br />
📢📢 ຖ້າລູກຄ້າມີຍອດຝາກ 300 ບາດຂື້ນໄປ ລູກຄ້າຈະໄດ້ໂປຊື້ເລກຟຮິກັບບ້ານຫວຍທາງເຮົາ 25000 ກີບ <br />
📢📢ຖ້າລູກຄ້າຫລີ້ນເທິນຄົບ 1 ເທົ່າແລ້ວ  ແຈ້ງແອດມິນໄດ້ເລີຍເດີຈ້າ
                                            </Accordion.Body>
                                        }
                                         {
                                            banner.name == 'banner5' && <Accordion.Body>
                                          ກິດຈະກຳສຳລັບສະມາຊິກ <br />
   ເລກທ້າຍຢູສເຊີ້ຕົງກັບຜົນຫວຍຖ້າເລກທ້າຍຢູສເຊີ້ຕົງກັບຜົນອອກຫວຍໃນມື້ນັ້ນ <br /><br />

1. ຫວຍຮານອຍພລັສ (ເວລາຫວຍອອກ 17:30)<br />
2. ຫວຍຮານອຍປົກກະຕິ (ເວລາຫວຍອອກ 18:30)<br />
3. ຫວຍຮານອຍວີໄອພີ (ເວລາຫວຍອອກ 19:30)<br />
4. ຫວຍລາວພັດທະນາ (ເວລາຫວຍອອກ 20:30)<br /><br />

( ກະຕິກາ ຈະຕ້ອງມີຍອດເຕີມ ແລະ ມີການເຕີມຫລິ້ນໃນມື້ນັ້ນ ຈື່ງຈະສາມາດໄດ້ຮັບເງິນລາງວັນ ເຕີມຂັ້ນຕ່ຳ 300 ບາດ ເທີນ 1 ເທົ່າ )
<br /><br />
ເລກທ້າຍຕົງກັບຫວຍໃດ ແຈ້ງຮັບເງິນລາງວັນໄດ້ເລີຍ<br />
ລາງວັນ 4 ໂຕຕົງ ຮັບ 4,000 ບາດ<br />
ລາງວັນ 3 ໂຕຕົງ ຮັບ 1,000 ບາດ<br />
ລາງວັນ 2 ໂຕຕົງ ຮັບ 500 ບາດ
                                            </Accordion.Body>
                                        }
                                        
                                    </Accordion.Item>
                                </Accordion>
                            </div>
                        </div>
                    })}

                </div>
            </div>
        </div>
    );
}
{
    /*
<h5>คืนยอดเสีย 5% ทุกวันศุกร์</h5>
➭ รับโบนัสคืนยอดเสียรวม สัปดาห์ (ตัดรอบ วันศุกร์ 11:00 ถึง 10:59
ของวันศุกร์อีกสัปดาห์)
<br/>➭ มียอดเสียมากกว่า 1000 บาทต่อสัปดาห์ และต้องมียอดเทิร์นโอเวอร์ 5 เท่า
ของยอดฝาก จึงจะได้รับคืนยอดเสีย 5 %
<br/>➭ รับคืนสูงสุด 5000 บาท
<br/>➭ เมื่อรับโปรโมชั่นเครดิตมีอายุการใช้งาน 1
วันจากนั้นเครดิตคืนยอดเสียจะถูกปรับเป็น 0
<br/>➭ โบนัสจะได้รับทุกวันศุกร์สามารถกดรับได้ที่หน้าเว็บตั้งแต่ 11:30
น. เป็นต้นไป

<button type="button" className="btnLogin my-3">
    <span>รับโปรโมชั่น</span>
</button>
    */
}

export default Promotion;
