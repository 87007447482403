
import {useSelector} from "react-redux";

import { addCommas } from '../config/Index'
import { LG } from '../config/Index.js'

const lgtext = {
    lao:{
      1:'ຖອນເງິນ',
      2:'ຍອດຖອນ',
      3:'ສະຖານະ',
      4:'ລໍຖ້າການໂອນ',
      5:'ຖ້າໂອນເຂົ້າຈັກໜ້ອຍເດີຈ້າວ',
      6:'ພາຍໃນ 5 ນາທີເງີນຢັງບໍ່ເຂົ້າຕິດຕໍ່ແອດມິນທີ່ເບີວ໋ອດແອບ',
    },
    thai:{
      1:'ถอนเงิน',
      2:'ยอดเงินที่ถอน',
      3:'สถานะ',
      4:'กำลังโอน',
      5:'กรุณารอโอนเงินสักครู่',
      6:'หากยอดเงินไม่เข้าภายใน 15 นาที กรุณาติดต่อแอดมิน',
    }
  }
function Withdraw() {
   
    const user = useSelector(state => state.user)
   
    return (
        <div className="ctpersonal mb-5">
                <div className="inboxmain">
                    <div className={'row'}>
                        <div className={'col-auto'}>
                            <img className={'circlered'} src={user?.item?.bankphoto}/>
                        </div>
                        <div className={'col-auto'}>
                            {user?.item?.bankname}<br/>
                            <span className="text-secondary">{user?.item?.accno} / {user?.item?.accname}</span>
                        </div>
                    </div>
                    <hr/>

                    <div className={'row'}>
                        <div className={'col'}>
                        {lgtext[LG]['2']}
                        </div>
                        <div className={'col-auto'}>
                            <h4 className={'mb-0'}> {addCommas(parseFloat(user?.accountwithdraw?.amount).toFixed(2))} (THB)</h4>
                        </div>
                    </div>
                    <hr/>

                    <div className={'row'}>
                        <div className={'col'}>
                        {lgtext[LG]['3']}
                        </div>
                        <div className={'col-auto'}>
                        {lgtext[LG]['4']}
                        </div>
                    </div>
                   
               
                   
                    <hr/>

                    <div className="modalspanbox">
                        <span>{lgtext[LG]['5']}</span><br/>
                        {lgtext[LG]['6']} {user?.webinfo?.whatappid}
                    </div>
                </div>
            </div>
    );
}

export default Withdraw;
