import {useSelector} from "react-redux";
import { APIURL, addCommas, checkcurrency } from '../config/Index'
import CountdownTimer from './CountdownTimer'
import QRCodeImg from "react-qr-code"
import { LG } from '../config/Index'

const lgtext = {
    lao:{
      1:'ຍອດໂອນ',
      2:'ຮັບເຄດິດ',
      3:'ເມື່ອໂອນແລ້ວກົດປຸ່ມ "ຍືນຢັນຝາກ" ດ້ານລຸ່ມ',
      4:'ຍືນຢັນຝາກ',
    },
    thai:{
      1:'ยอดฝาก',
      2:'รับเครดิต',
      3:'เมื่อโอนแล้วกรุณากด "ยืนยัน" ด้านล่าง',
      4:'ยืนยันฝาก',
    }
  }
function Profile({depositConfirm, depositReject}) {
    const { accountdeposit } = useSelector(state => state.user)
    return (
        <div className='tab-pane fade active show'>
                                         <div className="griddps">
                                    <div className="modalspanbox mt-3">
                                    <div className="iningriddps">
                                                    <img src={`${accountdeposit?.bankphoto}`} className="photobankqr"/>
                                            </div>
                                            {accountdeposit?.accname} <br />
                                            {accountdeposit?.accno} <br />
                                            {lgtext[LG]['1']} <button className='btnc1'>{addCommas(parseFloat(accountdeposit?.amount).toFixed(0))}</button> {checkcurrency(accountdeposit?.currency)} <br />
                                             <i class="fa-solid fa-arrow-down"></i> <br />
                                             {lgtext[LG]['2']} <button className='btnc2'>{addCommas(parseFloat(accountdeposit?.amountvalue).toFixed(0))}</button> THB <br />
                    {
                    accountdeposit?.promptpay == 1 ?  <QRCodeImg value={accountdeposit?.promptpaycode} size={200} /> : <img src={`${APIURL}/images/statement/${accountdeposit?.qrcode}`} className="photoqr"/> 
                  }
                                            
                                           
                                        </div>
                                        </div>
                                        <div className='text-center'>
                                        <CountdownTimer countdownTimestampMs={parseInt(accountdeposit?.mtimestamp)} />
                                        <div className="modalspanbox pt-2">
                        <span>{lgtext[LG]['3']}</span>
                       
                    </div>
                                        </div>
            <button type="button" onClick={depositConfirm} className="moneyBtn">ໂອນແລ້ວ ກົດຢືນຢັນ</button>
            <button type="button" onClick={depositReject} className="moneyBtn2">ຍົກເລີກ</button>
                                    </div>
    );
}

export default Profile;
