import 'antd/dist/reset.css';
import './Pink.scss';
//import './index.css';
import {
    createBrowserRouter,
    createRoutesFromElements,
    Route,
    RouterProvider,
} from 'react-router-dom'

// layout
import DefaultLayout from "./layout/Default";

// page
import Home from "./page/Home";
import Login from "./page/Login";
//import LandingPage from "./page/LandingPage";
//import Register from "./page/Register"; // Thai
import Register from "./page/RegisterLao"; // Lao
import Profile from "./page/Profile";
import ChangePassword from "./page/ChangePassword";
import CashBack from "./page/CashBack";
import Commission from "./page/Commission";
import Affiliate from "./page/Affiliate";
import History from "./page/History";
import Promotion from "./page/Promotion"; // Lao
//import Promotion from "./page/PromotionTh"; // Thai
import GameDetail from "./components/GameHeader";
import Deposit from "./page/DepositLaoNew"; // สำหรับ lao
//import Deposit from "./page/DepositTh"; // สำหรับ thai
import Withdraw from "./page/Withdraw";
import Contact from "./page/Contact";
import Game from "./page/Game";


const router = createBrowserRouter(
    createRoutesFromElements(
        <>
        
            <Route path="/" element={<DefaultLayout/>}>
                <Route index element={<Home/>}/>
                <Route path="/login" index element={<Login/>}/>
               
                <Route path="/register" index element={<Register/>}/>
                <Route path="/profile" index element={<Profile/>}/>
                <Route path="/change-password" index element={<ChangePassword/>}/>
                <Route path="/cashback" index element={<CashBack/>}/>
                <Route path="/affiliate" index element={<Affiliate/>}/>
                <Route path="/cashback" index element={<CashBack/>}/>
                <Route path="/commission" index element={<Commission/>}/>
                <Route path="/history" index element={<History/>}/>
                <Route path="/promotion" index element={<Promotion/>}/>
                <Route path="/contact" index element={<Contact/>}/>
                <Route path="/:provider" index element={<GameDetail/>}/>
                <Route path="/game" index element={<Game/>}/>
                <Route path="/deposit" index element={<Deposit/>}/>
                <Route path="/withdraw" index element={<Withdraw/>}/>
            </Route>
        </>
    ),{
        basename: process.env.REACT_APP_BASE_URL,
    }
)

function App() {
   
    return (
        <RouterProvider router={router}/>
    );
}

export default App;

/* 
ดาวโหลดรูปตัวอย่าง
https://ideabet.bet/theme/clashred/theme/images/bg/03.jpg

ชื่อ css ที่ต้องแก้
navbar ขวา
.insidebarleft

// login bg
section.sectionpage.login::before
*/
