import { useEffect, useRef, useMemo, useState } from 'react'
import {useDispatch, useSelector} from "react-redux";
import  { useNavigate } from 'react-router-dom'
import { message } from 'antd'
import {getbalance, memberwithdraw, checkwithdraw, setStep} from "../store/user";
import { addCommas } from '../config/Index'
import ModalLoading from '../components/ModalLoading'
import ModelApprove from '../components/ModelApproveWithdraw'
import WithdrawPending from './WithdrawPending'
import WithdrawPro from './WithdrawPro'
import WithdrawNoPro from './WithdrawNoPro'
import WithdrawSuccess from './WithdrawSuccess'
import { LG } from '../config/Index.js'
import VedioMoal from '../components/ModelRegister'

const lgtext = {
    lao:{
      1:'ຖອນເງິນ',
      2:'ຍອດເງິນທີ່ຖອນ',
      3:'ຈຳນວນເງິນທີ່ຕ້ອງການຖອນ',
      4:'ຍອດເງິນບໍ່ພຽງພໍເພື່ອຖອນ',
      5:'ກະລຸນາໃສ່ຈຳນວນເງິນ'
    },
    thai:{
      1:'ถอนเงิน',
      2:'ยอดเงินที่ถอนได้',
      3:'จำนวนเงิน',
      4:'ยอดเงินไม่พอ',
      5:'ใส่จำนวนเงิน',
    }
  }
function Withdraw() {
   
    const [show, setshow] = useState(false)
    const user = useSelector(state => state.user)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    useEffect(()=> {
        if(user.isLogged === true) {
        dispatch(checkwithdraw({}))
        dispatch(getbalance({}))
        }else{
            navigate('/')
        }
    } ,[])

    useMemo(() => {
        if(user?.accountwithdraw?.stepwithdraw == 2){
            dispatch(getbalance({}))
        }
    }, [user?.accountwithdraw?.stepwithdraw])
    const txtamountd = useRef(null)
    const onsubmit = () => {
        const amount = txtamountd.current.value
       
        if(parseFloat(amount)<0 || !amount) {
            txtamountd.current.focus()
        } else if(user?.accountwithdraw?.maxwithdraw > 0 && parseFloat(amount) > parseFloat(user?.accountwithdraw?.maxwithdraw)){
            message.error(`ถอนได้สูงสุดไม่เกิน ${user?.accountwithdraw?.maxwithdraw}`)
        } else {
            if(user?.isLoadingLogin == false) {
                dispatch(memberwithdraw({amount}))
                dispatch(getbalance({}))
            }
            
        }
       
    }
    return (
        <div>
            <div className="headsecion">
                <img src="./images/icon/withdraw.png"/> {lgtext[LG]['1']}
            </div>
            {
                user?.accountwithdraw?.stepwithdraw == 2 && <WithdrawPending />
            }
            {
                // กรณีทำโปรไม่ผ่าน
                user?.accountwithdraw?.stepwithdraw == 4 && user?.accountwithdraw?.statuspro === 'running' && <WithdrawPro />
            }

            {
                // กรณีทำไม่รับโปรและทำเทินไม่ถึง 1 เท่า
                user?.accountwithdraw?.stepwithdraw == 4 && user?.accountwithdraw?.statuspro === 'nopro' && <WithdrawNoPro />
            }

            {
                // กรณีทำโปรผ่าน
                user?.accountwithdraw?.stepwithdrawsuccess == 3 && user?.accountwithdraw?.statuspro === 'running' && <WithdrawSuccess />
            }
            
            {
                user?.accountwithdraw?.stepwithdraw == 1 && user?.accountwithdraw?.statuspro !== 'running' && <div className="ctpersonal mb-5">
                <div className="inboxmain">
                    <div className={'row'}>
                        <div className={'col-auto'}>
                            <img className={'circlered'} src={user?.item?.bankphoto}/>
                        </div>
                        <div className={'col-auto'}>
                            {user?.item?.bankname}<br/>
                            <span className="text-secondary">{user?.item?.accno} / {user?.item?.accname}</span>
                        </div>
                    </div>
                    <hr/>

                    {
                        // กรณีทำโปรผ่าน
                        user?.accountwithdraw?.stepwithdrawsuccess == 3 && user?.accountwithdraw?.statuspro === 'running' ? <div><div className={'row'}>
                        <div className={'col'}>
                        ยอดเงินทั้งหมด
                        </div>
                        <div className={'col-auto'}>
                            <h4 className={'mb-0'}>{addCommas(parseFloat(user?.item?.balance).toFixed(2))}</h4>
                        </div>
                    </div>
                    {
                        parseFloat(user?.accountwithdraw?.maxwithdraw) < parseFloat(user?.item?.balance) &&  <div className={'row'}>
                        <div className={'col'}>
                        ยอดถอนได้ตามโปร
                        </div>
                        <div className={'col-auto'}>
                            <h4 className={'mb-0'}> {addCommas(parseFloat(user?.accountwithdraw?.maxwithdraw).toFixed(0))}</h4>
                        </div>
                    </div>
                    }
                   {
                     parseFloat(user?.accountwithdraw?.maxwithdraw) < parseFloat(user?.item?.balance) && <div className={'row'}><div className={'col txtred'}>*หมายเหต : ยอดเงินส่วนเกินโปร จะถูกดึงกลับทั้งหมด</div></div>
                   }
                    
                    </div> : <div className={'row'}>
                        <div className={'col'}>
                        {lgtext[LG]['2']}
                        </div>
                        <div className={'col-auto'}>
                            <h4 className={'mb-0'}> {addCommas(parseFloat(user?.item?.balance).toFixed(2))}</h4>
                        </div>
                    </div>
                    }
                    
                    <hr/>

                    <div className={'row'}>
                        <div className={'col'}>
                        {lgtext[LG]['3']}
                        </div>
                        <div className={'col-auto'}>
                            (THB)
                        </div>
                    </div>
                    <div className={'row align-items-center'}>
                        <div className={'col-auto'}>
                            ฿
                        </div>
                        <div className={'col'}>
                            <input ref={txtamountd} className="inputmain" type="number" placeholder={lgtext[LG]['5']}/>
                        </div>
                    </div>
                   
                        {
                            user?.errcode == 413 && <div className="text-danger text-center">{lgtext[LG]['4']}</div>
                        }
                   
                    <hr/>

                    <button type="button" onClick={onsubmit} className="moneyBtn"> {lgtext[LG]['1']}</button>
                </div>
                {
                    LG == 'lao' && <div className='vedio1 text-center pt-3' onClick={()=>setshow(true)}><img src={`/images/howto/withdraw.png`} height={90} /></div>
                }
                
            </div>
            }
            <ModelApprove show={user?.accountwithdraw?.checkfinish == 'finish'} status={user?.accountwithdraw?.wsuccess} onHide={()=>{ dispatch(setStep({accountwithdraw:{stepwithdraw:1}}))}} />
            <ModalLoading show={user?.isLoadingLogin} onHide={()=>{}} />
            <VedioMoal show={show} filename='withdraw' onHide={()=>setshow(false)}/>
        </div>
    );
}

export default Withdraw;
